import styled from "styled-components";
import { calculateResponsiveSize } from "helpers/calculate-responsive-size";
import { mediaMax } from "helpers/media-queries";

export const MainIntroWrapper = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 320px;
  height: 100svh;
  padding: 20px 50px;
  background: rgba(0, 0, 0, 0.2);
  opacity: 1;
  animation: 2s ease 5.6s normal forwards 1 fadeOut;
  &.mobile {
    animation: 1.5s ease 3s normal forwards 1 fadeOut;
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  z-index: 15;
  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    background: #8c9ea3;
    opacity: 1;
    animation: 2s ease 3s normal forwards 1 fadeOut;
    z-index: 1;
    @keyframes fadeOut {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
`;

export const LogoWrapper = styled.div`
  width: ${calculateResponsiveSize(172)};
  opacity: 0;
  animation: 1s ease 0.6s normal forwards 1 fadeIn;
  position: relative;
  z-index: 2;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  ${mediaMax("tablet")} {
    width: 110px;
  }
  & > img {
    width: 100%;
  }
`;
