import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Transition, TransitionGroup } from "react-transition-group";
import { gsap, ScrollSmoother, ScrollTrigger } from "helpers/gsap";
import { FirstLoadProvider, FooterHeightProvider } from "helpers/context";
import Header from "components/header";
import Menu from "components/header/menu";
import Footer from "components/footer";
import MainIntro from "components/ui/main-intro";
import RotateScreen from "components/rotate-screen";
import NavigationPrevNext from "components/navigation-prev-next";
import useWindowSize from "helpers/use-window-size";
// import CookiesBanner from "components/ui/cookies-banner";

import "helpers/fonts.css";
import GlobalStyle from "helpers/global-styles";
import { LayoutWrapper, PageWrapper, ContentWrapper } from "./index.styled";

const LayoutContent = ({
  children,
  location,
  bindScrollTriggerToFadedElements,
}) => {
  return (
    <TransitionGroup component={null}>
      <Transition key={location.pathname} timeout={{ enter: 1000, exit: 1000 }}>
        {(status) => (
          <div
            className={`page ${status}`}
            onAnimationEnd={() => {
              if (status === "entered") {
                bindScrollTriggerToFadedElements();
              }
            }}
          >
            <LayoutWrapper>{children}</LayoutWrapper>
          </div>
        )}
      </Transition>
    </TransitionGroup>
  );
};

const Layout = (props) => {
  const {
    children,
    location,
    pageContext: { layout },
  } = props;
  const isHomePage = location.pathname === "/";
  const { isMobile } = useWindowSize();

  // ScrollSmoother
  const scrollerSmoother = React.useRef(null)
  const refWrapper = React.useRef(null)
  React.useLayoutEffect(() => {
    const gsapContext = gsap.context(() => {
      if (!isMobile) {
        scrollerSmoother.current = ScrollSmoother.create({
          content: ".home-content",
          wrapper: ".home-wrapper",
          smooth: 2,
          effects: true,
        });
      }
    }, refWrapper);

    return () => gsapContext.revert();
  }, [isMobile, children])

  // RotateScreen
  if (typeof window !== "undefined") {
    const hasTouchscreen = "ontouchstart" in window;

    if (hasTouchscreen && window.innerWidth < 980) {
      function updateOrientationClass() {
        const isLandscape = window.innerWidth > window.innerHeight;
        document.body.classList.toggle("landscape", isLandscape);
      }
      window.addEventListener("load", function () {
        updateOrientationClass();
      });
      window.addEventListener("resize", function () {
        updateOrientationClass();
      });
    }
  }

  // Animations
  const bindScrollTriggerToFadedElements = () => {
    gsap.utils.toArray(".fadeIn, .titleFadeIn").forEach(function (container) {
      ScrollTrigger.create({
        trigger: container,
        start: "top 90%",
        end: "+=1000%",
        toggleClass: {
          targets: container,
          className: "visible",
          toggle: "add",
        },
        once: true,
      });
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      bindScrollTriggerToFadedElements();
    }, 100);
    return () => clearTimeout(timeout);
  }, [children]);

  // Scroll to anchor
  useEffect(() => {
    if (location.hash) {
      const timeout = setTimeout(() => {
        const hashWithoutHash = location.hash.substring(1);
        const targetElement = document.querySelector(
          `#${hashWithoutHash}-section`
        );

        if (targetElement) {
          const offsetTop = targetElement.offsetTop - (isMobile ? 20 : 115);
          window.scrollTo({
            top: offsetTop,
            // behavior: "smooth",
          });
          const timeout2 = setTimeout(() => {
            window.scrollBy(0, -2);
          }, 300);
          return () => clearTimeout(timeout2);
        }
      }, 100);
      return () => clearTimeout(timeout);
    }
  }, [location.hash]);

  return (
    <FirstLoadProvider>
      <FooterHeightProvider>
        <RotateScreen />
        <div className="home-wrapper" ref={refWrapper}>
          {isHomePage && <MainIntro />}

          {!isMobile && <Header location={location} layout={layout} />}
          <Menu />
          <div className={["home-content", layout].join(" ")}>
            {isMobile && <Header location={location} layout={layout} />}
            <PageWrapper>
              <GlobalStyle />
              <ContentWrapper>
                <LayoutContent
                  location={location}
                  bindScrollTriggerToFadedElements={
                    bindScrollTriggerToFadedElements
                  }
                >
                  {children}
                </LayoutContent>
              </ContentWrapper>
              <NavigationPrevNext />
            </PageWrapper>
            <Footer />
          </div>
          {/* <CookiesBanner /> */}
        </div>
      </FooterHeightProvider>
    </FirstLoadProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};

export default Layout;
