import styled from "styled-components";
import { mediaMax } from "helpers/media-queries";

export const RotateScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 0;
  height: 0;
  background: #8c9ea3;
  overflow: hidden;
  visibility: hidden;
  z-index: 1000;
  ${mediaMax("mobileLandscape")} {
    .landscape & {
      width: 100%;
      height: 100%;
      visibility: visible;
    }
  }
`;

export const ImgWrapper = styled.div`
  width: 74px;
  margin-bottom: 24px;
`;

export const Title = styled.h1`
  max-width: 375px;
  margin: 0;
  text-align: center;
  font-size: 18px;
  color: #FFF6F0;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.16px;
  text-transform: uppercase;
`;
