import React, { useEffect, useContext } from "react";
import { FirstLoadContext } from "helpers/context";
import { MainIntroWrapper, LogoWrapper } from "./index.styled";
import Logo from "icons/200_East_75th_Street_Condominiums.svg";
import useWindowSize from "helpers/use-window-size";

const MainIntro = () => {
  const { isFirstLoad, setIsFirstLoad } = useContext(FirstLoadContext);
  const { isMobile } = useWindowSize();

  useEffect(() => {
    if (isFirstLoad) {
      const timeout = setTimeout(() => {
        window.scrollTo({ top: 0 });
      }, 100);
      const timeout2 = setTimeout(() => {
        setIsFirstLoad(false);
      }, isMobile ? 5000 : 8500);

      return () => {
        clearTimeout(timeout);
        clearTimeout(timeout2);
      };
    }
  }, [isFirstLoad, setIsFirstLoad ,isMobile]);

  return (
    <>
      {isFirstLoad && (
        <MainIntroWrapper className={isMobile ? "mobile" : ""}>
          <LogoWrapper>
            <img src={Logo} alt="200 East 75th Street Condominiums" />
          </LogoWrapper>
        </MainIntroWrapper>
      )}
    </>
  );
};

export default MainIntro;
