import React, { useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import {
  Wrapper,
  MenuWrap,
  CloseButton,
  Primary,
  Li,
  Item,
  Num,
  Submenu,
  Secondary,
  Links,
  LegalLink,
  Overlay,
} from "./index.styled";

const Menu = () => {
  const {
    menuContent: { content: links },
  } = useStaticQuery(graphql`
    query MenuQuery {
      menuContent {
        content {
          primary {
            num
            text
            url
            submenu {
              num
              text
              url
            }
          }
          secondary {
            text
            url
          }
        }
      }
    }
  `);

  const menuRef = useRef(null);
  const onClose = () => {
    menuRef.current.classList.remove("open");
  };

  return (
    <Wrapper id={"menu"} ref={menuRef}>
      <MenuWrap>
        <CloseButton onClick={onClose} />
        <Primary>
          {links.primary.map((item, index) => (
            <Li key={`primary-item-${index}`}>
              <Item to={item.url} onClick={onClose}>
                <Num>{item.num}</Num>
                {item.text}
              </Item>
              {item.submenu ? (
                <Submenu>
                  {item.submenu.map((subItem, j) => (
                    <Li key={`primary-item-${index}-${j}`}>
                      <Item to={subItem.url} onClick={onClose}>
                        <Num>{subItem.num}</Num>
                        {subItem.text}
                      </Item>
                    </Li>
                  ))}
                </Submenu>
              ) : null}
            </Li>
          ))}
        </Primary>
        <Secondary>
          {links.secondary.map((item, index) => (
            <Li key={`secondary-item-${index}`}>
              <Item to={item.url} className={"secondary"} onClick={onClose}>
                {item.text}
              </Item>
            </Li>
          ))}
        </Secondary>
        <Links>
          <LegalLink to={"/legal"} onClick={onClose}>Legal</LegalLink>
          <a href="/fairhousingnotice.pdf" target="_blank" rel="noreferrer" onClick={onClose}>FAIR HOUSING NOTICE</a>
          <a href="/NY-StandardOperatingProcedures.pdf" target="_blank" rel="noreferrer" onClick={onClose}>STANDARD OPERATING PROCEDURES</a>
        </Links>
      </MenuWrap>
      <Overlay onClick={onClose} />
    </Wrapper>
  );
};

export default Menu;
