import styled from "styled-components";

export const LayoutWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const PageWrapper = styled.div`
  position: relative;
`;

export const ContentWrapper = styled.div`
  position: relative;
  overflow: visible;
  height: 100%;
  width: 100%;
  .page {
    top: 0;
    height: 100%;
    width: 100%;
  }
  .page.entering,
  .page.entered {
    position: relative;
    opacity: 1;
    transition: opacity 0.25s;
  }
  .page.exiting,
  .page.exited {
    position: absolute;
    opacity: 0;
    transition: opacity 0;
  }
`;
