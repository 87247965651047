import React from "react";
import { RotateScreenWrapper, ImgWrapper, Title } from "./index.styled";
import ImgRotateScreen from "icons/ico_rotate.svg";

const RotateScreen = () => {
  return (
    <RotateScreenWrapper>
      <ImgWrapper>
        <img src={ImgRotateScreen} alt="" />
      </ImgWrapper>
      <Title>Please rotate your screen for best viewing experience</Title>
    </RotateScreenWrapper>
  );
};

export default RotateScreen;
